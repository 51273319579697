/* eslint-disable camelcase */
import { z } from 'zod';

const b64toUtf8 = (b64: string): string =>
  decodeURIComponent(
    Array.prototype.map
      .call(
        atob(b64),
        (c: string) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`,
      )
      .join(''),
  );

const UserJwtData = z.object({
  sub: z.string(),
  email: z.string().email(),
  'cognito:username': z.string(),
  // some more but not used
  // at_hash?: string;
  // aud?: string;
  // email_verified?: boolean;
  // event_id?: string;
  // token_use?: string;
  // auth_time?: number;
  // iss?: string;
  // exp?: number;
  // iat?: number;
});

type IUserJwtData = z.infer<typeof UserJwtData>;

const jwtParser = (authorizationHeader: string): IUserJwtData => {
  const ca = authorizationHeader.substring(7);
  const base64Url = ca.split('.')[1];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const userRaw = JSON.parse(b64toUtf8(base64Url));
  return UserJwtData.parse(userRaw);
};

export default jwtParser;
