// Specification: https://github.com/system-ui/theme-specification
import button from './button';
import box from './box';
import forms from './forms';
import text from './text';
import links from './links';

/* Declare "variables" */

// value         |0px     576px    768px    992px   1200px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl
const breakpoints = ['36em', '48em', '62em', '75em'];

const colors = {
  primary: '#fff2eb',
  background: '#fff',
  black: '#000000',
  baseBlack: '#111111',
  white: '#fff',
  grey1: '#F2F2F2',
  grey2: '#b0b1b2',
  grey3: '#979797',
  grey4: '#626669',
  grey5: '#323639',
  error: '#ef484c',
  warning: '#F6B387',
  success: '#A7D0BC',
};

const fonts = {
  body: 'Metropolis, sans-serif',
  heading: 'Montserrat, sans-serif',
};

//                [ 0,  1,  2,  3,  4,  5,  6,  7];
const fontSizes = [14, 16, 18, 24, 32, 42, 58, 80];
const fontWeights = {
  body: 400,
  heading: 700,
  bold: 700,
  thin: 200,
};
const lineHeights = {
  body: 1.3,
  heading: 1.25,
};
const radii = {
  default: 0,
  small: '0.6rem',
};

const space = [
  0, // 0: 0px
  '0.25rem', // 1: 4px
  '0.5rem', // 2: 8px
  '1.0rem', // 3: 16px
  '1.5rem', // 4: 24px
  '2.0rem', // 5: 32px
  '2.5rem', // 6: 40px
  '3.0rem', // 7: 48px
  '3.5rem', // 8: 56px
  '4.0rem', // 9: 64px
  '4.5rem', // 10: 72px
  '5.0rem', // 11: 80px
  '5.5rem', // 12: 88px
  '6.0rem', // 13: 96px
  '6.5rem', // 14: 104px
  '7.0rem', // 15: 112px
  '7.5rem', // 16: 120px
  '8.0rem', // 17: 128px
  '8.5rem', // 18: 136px
  '9.0rem', // 19: 144px
  '9.5rem', // 20: 152px
  '10.0rem', // 21: 160px
  '10.5rem', // 22: 168px
  '11.0rem', // 23: 176px
  '11.5rem', // 24: 184px
  '12.0rem', // 25: 192px
  '12.5rem', // 26: 200px
  '13.0rem', // 17: 208px
];

const sizes = [
  '0',
  '11rem',
  '18rem',
  '32rem',
  '43rem',
  '45rem',
  '56rem',
  '70rem',
  '80rem',
  '120rem',
  '160rem',
];

// Variants
// Theming theme-ui using the defined "variables"
const tokens = {
  buttons: button.variants || {},
  text: text.variants || {},
  box: box.variants || {},
  links: links.variants || {},
};

const customVariants = {
  box: box.variants || {},
};

const styles = {
  root: {
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
  },
  a: {
    variant: 'links.default',
  },
  hr: {
    borderColor: 'grey2',
    borderStyle: 'dashed',
  },
  p: {
    variant: 'text.default',
  },
  li: {
    variant: 'text.default',
  },
};

// Still need to export non-tokens, since theme-ui may use these values.
const theme = {
  useColorSchemeMediaQuery: false,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  forms,
  fontWeights,
  lineHeights,
  radii,
  styles,
  space,
  sizes,
  ...tokens,
  variants: customVariants,
};

export default theme;
