export default {
  variants: {
    cookie: {
      px: [3, 7],
      py: 3,
      position: 'fixed',
      zIndex: '99999',
      color: '#959899',
      backgroundColor: 'white',
      boxShadow: 'rgb(57 57 57 / 38%) 0px -3px 13px 0px',
      textAlign: 'left',
      right: '0',
      bottom: '0',
      left: '0',
      fontSize: 0,
    },
  },
};
