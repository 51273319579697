export default {
  label: {
    variant: 'text.small',
  },
  input: {
    display: 'block',
    bg: 'white',
    color: 'black',
    borderWidth: 0,
    borderRadius: 0,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'grey2',
    mb: 5,
    px: 1,
    fontFamily: 'body',
    fontSize: 1,
    '::placeholder': {
      color: 'black',
    },
    ':disabled': {
      color: 'grey2',
    },
    ':focus': {
      outline: 'none',
      borderBottomColor: 'black',
    },
    ':-webkit-autofill': {
      boxShadow: '0 0 0px 1000px white inset',
    },
  },
  variants: {
    inputbuttongroup: {
      bg: 'white',
      border: 0,
      borderRightWidth: '0',
      borderRadius: 'default',
      borderTopLeftRadius: 'small',
      borderBottomLeftRadius: 'small',
      flex: '1 1 0',
      py: 3,
      px: 5,
      ':focus': {
        outline: 'focus-ring-color auto 5px',
      },
    },
  },
};
