import env from './environment';

const isLocalDevelopment = process.env.NODE_ENV === 'development';
const baseUrl =
  isLocalDevelopment && typeof window !== 'undefined'
    ? `${window.location.protocol}//${window.location.host}`
    : env.GATSBY_BASE_URL;

const config = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: env.GATSBY_AWS_USER_POOL_ID,
    userPoolWebClientId: env.GATSBY_AWS_USER_POOL_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: env.GATSBY_AWS_OAUTH2_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: `${baseUrl}/callback`,
      redirectSignOut: `${baseUrl}/app`,
      responseType: 'code',
    },
  },
};
export default config;
