import * as React from 'react';

interface Env {
  apiBaseUrl: string;
  revenueCatApiKey: string;
  gracePeriod: number;
}

export const EnvContext = React.createContext<Env | undefined>(undefined);

interface Props {
  env: Env;
}

export function EnvProvider({ env, children }: React.PropsWithChildren<Props>) {
  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
}

export function useEnv() {
  const envContext = React.useContext(EnvContext);

  if (envContext == null) {
    throw new Error('useEnv() must be used within a EnvProvider');
  }

  return envContext;
}
