export default {
  variants: {
    default: {
      display: 'block',
      fontSize: 1,
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'black',
      maxWidth: '100%', // Needed for IE11. Privacy policy would break page
      letterSpacing: '.02em',
    },
    small: {
      variant: 'text.default',
      color: 'black',
      fontSize: [0, 0],
    },
    sizeTinyBold: {
      fontSize: [0, 0],
      fontWeight: 'bold',
    },
    smallMontserrat: {
      variant: 'text.heading',
      fontWeight: 'body',
      lineHeight: 1.4,
      color: 'black',
      fontSize: [0, 0],
    },
    ghost: {
      variant: 'text.default',
      color: 'rgba(0,0,0,0.4)',
    },
    heading: {
      variant: 'text.default',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    hMega: {
      variant: 'text.heading',
      lineHeight: ['heading', 0.95],
      fontSize: [5, 7],
    },
    h1: {
      variant: 'text.heading',
      textTransform: 'uppercase',
      fontSize: 5,
      marginTop: 14,
      marginBottom: 14,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
      marginBottom: 7,
      marginTop: 5,
    },
    h2light: {
      variant: 'text.h2',
      fontWeight: 'body',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
      marginBottom: 3,
      marginTop: 4,
    },
    h3light: {
      variant: 'text.h3',
      fontWeight: 'body',
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
      marginBottom: 0.5,
      marginTop: 0.75,
    },
    h4light: {
      variant: 'text.h4',
      fontWeight: 'body',
    },
    h5: {
      variant: 'text.heading',
      fontWeight: 'bold',
      fontSize: 1,
      marginBottom: 0,
      marginTop: 0,
    },
    h5light: {
      variant: 'text.h5',
      fontWeight: 'body',
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
      marginBottom: 0,
      marginTop: 2,
    },
    h6light: {
      variant: 'text.h6',
      fontWeight: 'body',
    },
    h1mobile: {
      marginTop: [1],
      marginBottom: [1],
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 'bold',
      fontSize: 4,
    },
    h1dark: {
      variant: 'text.h1',
      color: 'white',
    },
    h2dark: {
      variant: 'text.h2',
      color: 'white',
    },
    h3dark: {
      variant: 'text.h3',
      color: 'white',
    },
    h4dark: {
      variant: 'text.h4',
      color: 'white',
    },
    h5dark: {
      variant: 'text.h5',
      color: 'white',
    },
    footer: {
      variant: 'text.small',
      whiteSpace: 'nowrap',
      color: 'black',
    },
    knockout: {
      variant: 'text.heading',
      fontSize: [10, 9, 10, 11],
      fontWeight: 'bold',
      lineHeight: 1.15,
    },
    section: {
      title: {
        variant: 'text.h1',
        textAlign: 'center',
        pb: 4,
      },
      subtitle: {
        variant: 'text.heading',
        lineHeight: 'body',
        fontWeight: 'body',
        color: 'grey_4',
        textAlign: 'center',
        fontSize: [2, 3, 4],
      },
    },
  },
};
