export default {
  variants: {
    default: {
      fontSize: [0, null, null, null, 1],
      fontFamily: 'body',
      fontWeight: 'bold',
      lineHeight: 'body',
      color: 'primary',
      textDecoration: 'underline',
      ':focus': {
        color: 'primary',
      },
      ':hover': {
        color: 'primary',
      },
      ':active': {
        color: 'primary',
      },
    },
    small: {
      variant: 'links.default',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'grey_4',
      maxWidth: '100%', // Needed for IE11. Privacy policy would break page
      letterSpacing: '.02em',
      fontSize: [0, 0, 0, 0, 0],
    },
    textnormal: {
      variant: 'links.default',
      fontSize: [1, 1, 1, 1, 2],
      fontWeight: 'normal',
      textDecoration: 'none',
    },
    skipnav: {
      variant: 'links.default',
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      width: '1px',
      margin: '-1px',
      padding: 0,
      overflow: 'hidden',
      position: 'absolute',
      ':focus': {
        color: 'primary',
        padding: 3,
        position: 'fixed',
        top: 3,
        left: 3,
        background: 'white',
        zIndex: 3,
        width: 'auto',
        height: 'auto',
        clip: 'auto',
      },
    },
    footer: {
      lineHeight: 1,
      my: 3,
      color: 'grey5',
      fontFamily: 'heading',
      fontSize: '12px',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    footerDarkTransparent: {
      lineHeight: 1,
      my: 3,
      color: 'white',
      fontFamily: 'heading',
      fontSize: '12px',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    footerDarkSolid: {
      lineHeight: 1,
      my: 3,
      color: 'white',
      fontFamily: 'heading',
      fontSize: '12px',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  },
};
